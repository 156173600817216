import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const ReportTutorial = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [language, setLanguage] = useState('male');
  const [isChild, setIsChild] = useState(0);
  const [provinceId, setProvinceId] = useState(1);
  const [districtId, setDistrictId] = useState(1);
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [currentDistricts, setCurrentDistricts] = useState([])
  const [isCorrect, setIsCorrect] = useState(true);
  const [hasModal, setHasModal] = useState(false);

  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >
          <div className='text-center'>
            <h1 style={{
              color: '#2dc001',
              fontSize: '26px',
              textAlign: 'center',
              fontFamily: 'UTM-APTIMABOLD'
            }}>
              HƯỚNG DẪN
            </h1>
            <h1 style={{
              color: '#0172ff',
              fontSize: '44px',
              textAlign: 'center',
              fontFamily: 'UTM-APTIMABOLD'
            }}>
              ĐỌC BÁO CÁO
            </h1>
          </div>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(89, 89, 89, 0.5)',
            }}
          >
          </div>

        </div>

        <div>
          <img src={toAbsoluteUrl('/media/logos/r1.jpg')} style={{ width: WIDTH }} alt='intro' />
          <img src={toAbsoluteUrl('/media/logos/r2.jpg')} style={{ width: WIDTH }} alt='intro' />
          <img src={toAbsoluteUrl('/media/logos/r3.jpg')} style={{ width: WIDTH }} alt='intro' />
        </div>

        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >
          <a href="https://www.robotanan.com/quatanganbi" target='_blank'>
            <button
              disabled={ isCorrect ? '' : 'disabled' }
              type="button"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#2dc001',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bolder',
                  color: '#fff',
                  letterSpacing: 2.0
                }}
              >
                VỀ ANAN VÀ ANBI
              </span>
            </button>
          </a>

        </div>


      </div>

    </div>
  )
}

export {ReportTutorial}
