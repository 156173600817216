/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";

const StepSix = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(false);
  const [questionThree, setQuestionThree] = useState('');

  const userInfo = useSelector(state => state.userInfo)
  const isParentHelp = userInfo.is_parent_help === 1
  const LANGUAGE = userInfo.language

  useEffect(() => {
    if (questionThree) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [questionThree])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <h1
            style={{
              color: '#003345',
              fontSize: '24px',
              textAlign: 'center',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Trong mối quan hệ với người khác, bạn ưu tiên điều gì ?'
                :
                'In your relationships with others, what are your priorities?'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Bạn nhỏ nghiêng về xu hướng nào hơn trong mối quan hệ với người khác ?'
                :
                'Which direction is your child more inclined towards in relationships with others?'
              }
            </>}

          </h1>
          <div
            style={{
              height: '60px',
            }}
          >
          </div>


          <div
            onClick={() => {setQuestionThree("THREE_A")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionThree === "THREE_A" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              backgroundColor: questionThree === "THREE_A" ? '#00a600' : 'white',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Tạo dựng mối quan hệ sâu sắc và tình cảm'
                :
                'Build deep and emotional relationships'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Tạo dựng mối quan hệ sâu sắc và tình cảm'
                :
                'Build deep and emotional relationships'
              }
            </>}


          </div>
          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionThree === "THREE_B" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionThree === "THREE_B" ? '#00a600' : 'white',
              fontFamily: 'UTM-APTIMA'
            }}
            onClick={() => {setQuestionThree("THREE_B")}}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Duy trì sự đáng tin cậy và trách nhiệm'
                :
                'Maintain trustworthiness and responsibility'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Duy trì sự đáng tin cậy và trách nhiệm'
                :
                'Maintain trustworthiness and responsibility'
              }
            </>}


          </div>

          <div
            style={{
              height: '100px',
            }}
          >
          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#00a600' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)
              dispatch({
                type: 'SET_QUESTION_THREE',
                payload: {
                  questionThree: questionThree
                }
              })
              history.push('/step-seven')
            }}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMA'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC'
                :
                'CONTINUE'
              }

            </span>
          </button>

        </div>


      </div>

    </div>
  )
}

export {StepSix}
