/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";

const StepSeven = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(false);
  const [questionFour, setQuestionFour] = useState('');

  const userInfo = useSelector(state => state.userInfo)
  const isParentHelp = userInfo.is_parent_help === 1
  const LANGUAGE = userInfo.language

  useEffect(() => {
    if (questionFour) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [questionFour])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >




        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <h1
            style={{
              color: '#003345',
              fontSize: '24px',
              textAlign: 'center',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Bạn đánh giá như nào về mối quan hệ với những người xung quanh ?'
                :
                'How do you evaluate your relationships with the people around you?'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Quý vị đánh giá như nào về mối quan hệ của bạn nhỏ với những người xung quanh ?'
                :
                'How do you evaluate your child\'s relationship with the people around him?'
              }
            </>}


          </h1>
          <div
            style={{
              height: '60px',
            }}
          >
          </div>


          <div
            onClick={() => {setQuestionFour("FOUR_A")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionFour === "FOUR_A" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              backgroundColor: questionFour === "FOUR_A" ? '#00a600' : 'white',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Cảm thấy không an toàn, dễ nghi ngờ và đôi khi tự cô lập mình'
                :
                'Feeling insecure, suspicious and sometimes isolating yourself'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Bạn nhỏ cảm thấy không an toàn, dễ nghi ngờ và đôi khi tự cô lập mình'
                :
                'Children feel insecure, suspicious and sometimes isolate themselves'
              }
            </>}


          </div>
          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionFour === "FOUR_B" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionFour === "FOUR_B" ? '#00a600' : 'white',
              fontFamily: 'UTM-APTIMA'
            }}
            onClick={() => {setQuestionFour("FOUR_B")}}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Cố gắng duy trì sự chân thành và cởi mở dù mối quan hệ không hoàn hảo'
                :
                'Try to remain honest and open even if the relationship isn\'t perfect'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Bạn nhỏ cố gắng duy trì sự chân thành và cởi mở dù mối quan hệ không hoàn hảo'
                :
                'Young people try to maintain sincerity and openness even though the relationship is not perfect'
              }
            </>}

          </div>

          <div
            style={{
              height: '100px',
            }}
          >
          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#00a600' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)
              dispatch({
                type: 'SET_QUESTION_FOUR',
                payload: {
                  questionFour: questionFour
                }
              })
              history.push('/step-eight')
            }}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC'
                :
                'CONTINUE'
              }

            </span>
          </button>

        </div>


      </div>

    </div>
  )
}

export {StepSeven}
