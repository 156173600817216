/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";

const StepFour = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(false);
  const [questionOne, setQuestionOne] = useState('');

  const userInfo = useSelector(state => state.userInfo)
  const isParentHelp = userInfo.is_parent_help === 1
  const LANGUAGE = userInfo.language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (questionOne) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [questionOne])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >

          <p
            style={{
              color: '#2abf00',
              fontSize: '32px',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Khảo sát'
              :
              'Survey'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-20px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'SỨC KHỎE'
              :
              'MENTAL'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-25px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'TINH THẦN'
              :
              'HEALTH'
            }
          </p>

          <p
            style={{
              color: '#000',
              fontSize: '26px',
              fontWeight: 'bolder',
              textAlign: 'justify',
              fontFamily: 'UTM-APTIMAITALIC'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Câu hỏi 1:'
              :
              'Question 1:'
            }
          </p>

          <p
            style={{
              color: '#000',
              fontSize: '24px',
              textAlign: 'justify',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Đâu là hạn chế khiến bạn muốn cải thiện nhất để hạnh phúc hơn?'
                :
                'What is the limitation that you most want to improve to be happier?'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Đâu là hạn chế khiến bạn muốn cải thiện nhất để hạnh phúc hơn?'
                :
                'What is the limitation that you most want to improve to be happier?'
              }
            </>}

          </p>
          <div
            style={{
              height: '20px',
            }}
          >
          </div>


          <div
            onClick={() => {setQuestionOne("ONE_A")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionOne === "ONE_A" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              backgroundColor: questionOne === "ONE_A" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Quá tập trung vào thành tựu mà bỏ qua cảm xúc của bản thân và người khác'
                :
                'Too focused on achievements and ignore the feelings of yourself and others'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Quá tập trung vào thành tựu mà bỏ qua cảm xúc của bản thân và người khác'
                :
                'Too focused on achievements and ignore the feelings of yourself and others'
              }
            </>}
          </div>

          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionOne === "ONE_B" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionOne === "ONE_B" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
            onClick={() => {setQuestionOne("ONE_B")}}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Suy nghĩ quá nhiều và không thích hòa nhập với đám đông'
                :
                'Thinks too much and doesn\'t like to mingle with the crowd'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Suy nghĩ quá nhiều và không thích hòa nhập với đám đông'
                :
                'Thinks too much and doesn\'t like to mingle with the crowd'
              }
            </>}
          </div>

          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionOne === "ONE_C" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionOne === "ONE_C" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
            onClick={() => {setQuestionOne("ONE_C")}}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Thiếu quyết đoán và ngại đối diện xung đột'
                :
                'Indecisive and afraid to face conflict'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Thiếu quyết đoán và ngại đối diện xung đột'
                :
                'Indecisive and afraid to face conflict'
              }
            </>}
          </div>

          <div
            style={{
              height: '50px',
            }}
          >
          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)
              dispatch({
                type: 'SET_QUESTION_ONE',
                payload: {
                  questionOne: questionOne
                }
              })
              history.push('/step-five')
            }}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'XÁC NHẬN'
                :
                'CONFIRM'
              }
            </span>
          </button>

        </div>


      </div>

    </div>
  )
}

export {StepFour}
