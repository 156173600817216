/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";

const StepTwo1 = () => {
  const history = useHistory()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(false);
  const [hasModal, setHasModal] = useState(false);
  const [question, setQuestion] = useState('');

  const userInfo = useSelector(state => state.userInfo)
  const isParentHelp = userInfo.is_parent_help === 1
  const LANGUAGE = userInfo.language

  useEffect(() => {
    if (question) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [question])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >



        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <h1
            style={{
              color: '#000345',
              fontSize: '28px',
              textAlign: 'center',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'KHẢO SÁT'
              :
              'SURVEY'
            }
          </h1>
          <h1
            style={{
              color: '#000345',
              fontSize: '28px',
              textAlign: 'center',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'NHU CẦU HỌC TẬP'
              :
              'LEARNING NEEDS'
            }
          </h1>

          <div
            style={{
              height: '40px',
            }}
          >
          </div>

          <h1
            style={{
              color: '#000345',
              fontSize: '24px',
              textAlign: 'justify',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Anbi xây dựng những chương trình đào tạo để giúp bạn không chỉ khám phá mà còn hoàn thiện bản thân. Hãy giúp chúng tôi tạo nên những bài học phù hợp nhất với bạn.'
                :
                'Anbi builds training programs to help you not only discover but also improve yourself. Help us create lessons that work best for you.'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Anbi xây dựng những chương trình đào tạo để giúp bạn nhỏ không chỉ được khám phá mà còn hoàn thiện bản thân. Quý vị phụ huynh hãy giúp chúng tôi tạo nên những bài học phù hợp nhất với bạn nhỏ nhà mình.'
                :
                'Anbi builds training programs to help children not only discover but also improve themselves. Parents, please help us create the most suitable lessons for your children.'
              }
            </>}

          </h1>

          <div
            style={{
              height: '60px',
            }}
          >
          </div>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(89, 89, 89, 0.5)',
            }}
          >
          </div>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              width: '380px',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '15px',
              backgroundColor: 'white',
              border: '4px solid red',
              textAlign: 'center',
              borderRadius: '10px',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            <img src={toAbsoluteUrl('/media/logos/warning.png')} style={{ width: '100px', margin: '30px'}} alt='' />
            <h1 style={{ fontSize: '30px' }}>
              {LANGUAGE === 'VI'
                ?
                'LƯU Ý'
                :
                'WARNING'
              }
            </h1>
            <h3 style={{ fontSize: '24px', fontFamily: 'UTM-APTIMA' }}>
              {LANGUAGE === 'VI'
                ?
                'Nếu bạn bỏ qua, bạn có thể sẽ không nhận được khóa học phù hợp nhất với bạn'
                :
                'If you skip it, you may not get the course that is best for you'
              }
            </h3>
            <div
              onClick={() => history.push('/step-two2')}
              style={{
                width: '100%',
                border: '2px solid #ddd',
                borderRadius: '10px',
                paddingLeft: '15px',
                paddingRight: '15px',
                fontSize: '22px',
                fontWeight: 'bolder',
                backgroundColor: '#00a600',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                marginTop: '20px',
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'THAM GIA KHẢO SÁT'
                :
                'PARTICIPATE IN THE SURVEY'
              }
            </div>
            <div
              onClick={() => history.push('/step-three')}
              style={{
                width: '100%',
                border: '2px solid #ddd',
                borderRadius: '10px',
                paddingLeft: '15px',
                paddingRight: '15px',
                fontSize: '22px',
                fontWeight: 'bolder',
                backgroundColor: '#0d77d0',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                marginTop: '20px',
                marginBottom: '20px',
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'XÁC NHẬN BỎ QUA'
                :
                'CONFIRM the DISMISSAL'
              }
            </div>
          </div>

          <div
            onClick={() => {setQuestion("ONE_A")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '26px',
              fontWeight: 'bolder',
              color: question === "ONE_A" ? 'white' : '#bebebe',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              backgroundColor: question === "ONE_A" ? '#00a600' : 'white',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Tham gia khảo sát'
                :
                'Participate in surveys'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Tham gia khảo sát'
                :
                'Participate in surveys'
              }
            </>}

          </div>

          <div
            onClick={() => {setQuestion("ONE_B")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '26px',
              fontWeight: 'bolder',
              color: question === "ONE_B" ? 'white' : '#bebebe',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              backgroundColor: question === "ONE_B" ? '#00a600' : 'white',
              marginTop: '20px',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Bỏ qua'
                :
                'Skip'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Bỏ qua'
                :
                'Skip'
              }
            </>}

          </div>

          <div
            style={{
              height: '60px',
            }}
          >
          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#00a600' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)
              if (question === 'ONE_A') {
                // tham gia khao sat
                history.push('/step-two2')
              } else {
                // bo qua
                setHasModal(true)
              }
            }}
          >
            <span
              style={{
                fontSize: '26px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC'
                :
                'CONTINUE'
              }
            </span>
          </button>

        </div>


      </div>

    </div>
  )
}

export {StepTwo1}
