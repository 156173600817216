/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const StepTwo = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [birthDay, setBirthDay] = useState('01');
  const [birthMonth, setBirthMonth] = useState('01');
  const [birthYear, setBirthYear] = useState('2000');
  const [gender, setGender] = useState('male');
  const [isChild, setIsChild] = useState(null);
  const [provinceId, setProvinceId] = useState(1);
  const [districtId, setDistrictId] = useState(1);
  const [isCorrect, setIsCorrect] = useState(false);

  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language
  let years = []
  for (let i = 1950; i <= 2024; i++) {
    years.push(i)
  }

  useEffect(() => {
    if (isChild != null) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [isChild])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >




        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <h1 style={{
            color: '#6858ab',
            fontSize: '30px',
            fontFamily: 'UTM-APTIMABOLD'
          }}>
            {LANGUAGE === 'VI'
              ?
              'THÔNG TIN ĐĂNG KÝ'
              :
              'REGISTRATION INFORMATION'
            }
          </h1>
          <h3
            style={{
              color: '#ff6c00',
              fontSize: '22px',
              lineHeight: 1.8,
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Xin vui lòng nhập thông tin chính xác và đầy đủ của bạn'
              :
              'Please enter your correct and complete information'
            }

          </h3>
          <div
            style={{
              height: '50px',
            }}
          >
          </div>

          {/*nam sinh*/}
          <h3 style={{ color: '#aaa',
            fontSize: '22px',
            fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Năm sinh'
              :
              'Year of birth'
            }

          </h3>
          <select
            onChange={(e) => setBirthYear(e.target.value)}
            value={birthYear}
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {years.map((year) =>
              <option value={year} key={year}>{year}</option>
            )}

          </select>
          {/*nam sinh*/}

          <h3 style={{marginTop: '20px', color: '#aaa',
            fontFamily: 'UTM-APTIMA',
            fontSize: '22px'
          }}>
            {LANGUAGE === 'VI'
              ?
              'Loại báo cáo'
              :
              'Report type'
            }
          </h3>
          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: isChild === 0 ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: isChild === 0 ? '#00a600' : 'white',
              fontFamily: 'UTM-APTIMA'
            }}
            onClick={() => {
              setIsChild(0)
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'NGƯỜI LỚN: trên 18 tuổi'
              :
              'ADULTS: over 18 years old'
            }
          </div>
          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: isChild === 1 ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: isChild === 1 ? '#00a600' : 'white',
              fontFamily: 'UTM-APTIMA'
            }}
            onClick={() => {
              setIsChild(1)
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'HỌC SINH: 10 - 18 tuổi'
              :
              'PUPIL: 10 - 18 years old'
            }
          </div>
          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: isChild === 2 ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: isChild === 2 ? '#00a600' : 'white',
              fontFamily: 'UTM-APTIMA'
            }}
            onClick={() => {
              setIsChild(2)
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'HỌC SINH NHỎ: 4 - 10 tuổi, phụ huynh đăng ký giúp'
              :
              'YOUNG STUDENTS: 4 - 10 years old, parents can register to help'
            }
          </div>

          {/*note do chon loai bao cao*/}
          <h3
            style={{
              color: '#ff6c00',
              lineHeight: 1.8,
              marginTop: '20px',
              fontSize: '20px',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              '*Khuyến cáo: nếu bạn nhỏ hơn 15 tuổi, xin vui lòng chọn loại Báo cáo Học sinh. Đây là Báo cáo phù hợp nhất cho bạn theo nghiên cứu của chúng tôi!'
              :
              '*Recommended: if you are under 15 years old, please select the Pupil Report category. Here is the most relevant Report for you according to our research!'
            }
          </h3>
          {/*note do chon loai bao cao*/}

          <div
            style={{
              height: '30px',
            }}
          >
          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#00a600' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)

              if (isChild === 2) {
                dispatch({
                  type: 'SET_IS_PARENT_HELP',
                  payload: {
                    isParentHelp: 1
                  }
                })

                dispatch({
                  type: 'SET_STEP_TWO',
                  payload: {
                    birthDay,
                    birthMonth,
                    birthYear,
                    gender,
                    isChild: 1,
                    provinceId,
                    districtId
                  }
                })
              } else {
                dispatch({
                  type: 'SET_IS_PARENT_HELP',
                  payload: {
                    isParentHelp: 0
                  }
                })

                dispatch({
                  type: 'SET_STEP_TWO',
                  payload: {
                    birthDay,
                    birthMonth,
                    birthYear,
                    gender,
                    isChild,
                    provinceId,
                    districtId
                  }
                })
              }
              history.push('/step-two1')
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC'
                :
                'CONTINUE'
              }

            </span>
          </button>
        </div>


      </div>

    </div>
  )
}

export {StepTwo}
