import React from 'react'
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {useHistory} from "react-router-dom";

const NoteScan = () => {
  const history = useHistory()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '20px 20px 20px 20px'
          }}
        >
          <p className='text-primary fs-1 fw-bolder text-uppercase'>NOTES BEFORE ANALYZING FACES WITH ARTIFICIAL INTELLIGENCE</p>
          <p className='text-black-50 fs-2 fw-bolder'>1. Select “Allow" when prompted by the browser</p>
          <p className='text-black-50 fs-2 fw-bolder'>2. You can close your eyes while analyzing</p>
          <p className='text-black-50 fs-2 fw-bolder'>3. The device battery needs to be higher than 20% for the camera to run</p>
          <p className='text-black-50 fs-2 fw-bolder'>4. Place your face in the center of the circle until the “START ANALYSIS” button appears, press the button and hold it for 5 seconds so that the camera can capture the heat map and the system can analyze the data.</p>
          <button
            type="button"
            style={{
              display: 'block',
              width: '100%',
              padding: '15px',
              backgroundColor: '#0bc732',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px',
              fontFamily: 'UTM-APTIMA',
              marginTop: '25px'
            }}
            onClick={() => {
              history.push('/step-eight')
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0
              }}
            >
              START
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export {NoteScan}
