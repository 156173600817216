/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {SET_STEP_ONE} from "../../setup/redux/userReducer";

const StepOne = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const initReferPhone = searchParams.get("refer-phone");

  const [name, setName] = useState('');
  const [birthYear, setBirthYear] = useState('2000');
  const [phone, setPhone] = useState('');
  const [referPhone, setReferPhone] = useState(initReferPhone);
  const [familyPhone, setFamilyPhone] = useState('');
  const [familyRole, setFamilyRole] = useState('');
  const [familyRoles, setFamilyRoles] = useState([]);
  const [agencyAlias, setAgencyAlias] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [gender, setGender] = useState('male');
  const [hasModal, setHasModal] = useState(false);
  const [hasModalWarning, setHasModalWarning] = useState(false);
  const [questionOne, setQuestionOne] = useState('');
  const [questionTwo, setQuestionTwo] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (name && phone && agencyAlias) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [name, phone, agencyAlias, familyPhone, familyRole, birthYear])

  const confirmRegister = () => {
    setIsCorrect(false)
    axios.post(`${BACKEND_URL}/customer/us-register`, {
      name: name,
      phone: phone,
      password: phone,
      birth_year: userInfo.birth_year,
      gender: gender,
      question_two: questionTwo,
      partner_phone: referPhone,
    }).then(res => res.data)
      .then(resData => {
        setIsCorrect(true)
        console.log({resData})
        if (resData.error_code === 1) {
          dispatch({
            type: SET_STEP_ONE,
            payload: {
              name: name,
              birthYear: birthYear,
              phone: phone,
              password: phone,
              agencyAlias: agencyAlias,
              familyPhone: familyPhone,
              familyRole: familyRole,
              gender: gender,
              scanCode: resData.data.new_user.scan_code,
              sale: resData.data.agency,
            }
          })
          history.push('/note-scan')
        } else {
          setErrorMsg(resData.message ?? 'Đăng ký không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
        }
      })
      .catch(err => {
        console.log({err})
        setErrorMsg('Lỗi hệ thống, vui lòng thử lại hoặc liên hệ với quản trị viên!')
      })
  }

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div className='px-10 pt-10 pb-0'>
          <p className='text-success fw-bolder text-uppercase'
             style={{
               fontSize: '40px'
             }}
          >Sign up</p>
          <p className='text-primary fs-2 fw-bolder text-uppercase'>personality analysis technology using artificial intelligence cameras</p>
        </div>

        <div
          style={{
            width: '100%',
            padding: '0px 30px 20px 30px'
          }}
        >
          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            }}
          >
          </div>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              width: '350px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              textAlign: 'center',
              borderRadius: '10px',
              fontFamily: 'UTM-APTIMABOLD',
              overflow: 'scroll',
              padding: '20px'
            }}
          >
            <img src={toAbsoluteUrl('/media/logos/note-web.png')} style={{ width: '100%', height: 'auto' }} alt='' />

            <div
              onClick={() => setHasModal(false)}
              style={{
                width: '60%',
                border: '1px solid #ddd',
                borderRadius: '10px',
                fontSize: '22px',
                fontWeight: 'bolder',
                backgroundColor: '#30a3fc',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                fontFamily: 'UTM-APTIMABOLD',
                margin: 'auto',
                marginTop: '20px',
                cursor: 'pointer'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TÔI ĐÃ HIỂU'
                :
                'I UNDERSTAND'
              }
            </div>

            <div style={{ height: '20px' }}></div>

          </div>

          <div
            style={{
              display: hasModalWarning ? 'block' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(89, 89, 89, 0.5)',
            }}
          >
          </div>

          <div
            style={{
              display: hasModalWarning ? 'block' : 'none',
              position: 'fixed',
              width: '380px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              textAlign: 'center',
              borderRadius: '10px',
              fontFamily: 'UTM-APTIMABOLD',
              paddingBottom: '20px',
              height: '100%',
              overflow: 'scroll'
            }}
          >
            <img src={toAbsoluteUrl('/media/logos/canh-bao-anbi.jpg')} style={{ width: '340px', height: 'auto'}} alt='' />

            <div
              onClick={() => {
                // cho ve section id khao sat
                setHasModalWarning(false)
                const element = document.getElementById('survey-section');
                element?.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
              style={{
                width: '90%',
                border: '1px solid #ddd',
                borderRadius: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
                backgroundColor: '#30a3fc',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                marginTop: '20px',
                marginBottom: '20px',
                fontFamily: 'UTM-APTIMA',
                margin: 'auto',
                cursor: 'pointer'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC KHẢO SÁT'
                :
                'CONTINUE SURVEY'
              }
            </div>

            <div style={{ height: '16px' }}></div>

            <div
              onClick={() => {
                // tiep tuc dang ky
                setHasModalWarning(false)
                confirmRegister()
              }}
              style={{
                width: '90%',
                border: '1px solid #ddd',
                borderRadius: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
                backgroundColor: '#d0d0d0',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                marginTop: '20px',
                marginBottom: '20px',
                fontFamily: 'UTM-APTIMA',
                margin: 'auto',
                cursor: 'pointer'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'XÁC NHẬN BỎ QUA'
                :
                'CONFIRM SKIP'
              }
            </div>

          </div>

          <div>
            <p
              className='text-warning'
              style={{
                fontSize: '24px',
                textAlign: 'justify',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px'
              }}
            >
              Important Note
            </p>
            <p
              style={{
                fontSize: '18px',
                fontWeight: 'bolder',
                fontFamily: 'UTM-APTIMA',
                color: '#2d2d2d',
              }}
            >
              1. Committed to protecting user data, all data is encrypted at the highest level
            </p>
            <p
              style={{
                fontSize: '18px',
                fontWeight: 'bolder',
                fontFamily: 'UTM-APTIMA',
                color: '#2d2d2d',
              }}
            >
              2. Before proceeding with facial analysis, please close all other applications on your phone or computer.
            </p>
            <p
              style={{
                fontSize: '18px',
                fontWeight: 'bolder',
                fontFamily: 'UTM-APTIMA',
                color: '#2d2d2d',
              }}
            >
              Thank you!
            </p>
          </div>

          <p
            className='d-none'
            style={{
            color: '#2d308f',
            fontSize: '30px',
            fontFamily: 'UTM-APTIMABOLD',
          }}>
            {LANGUAGE === 'VI'
              ?
              'Thông tin'
              :
              'Information'
            }
          </p>
          <p
            className='d-none'
            style={{
            color: '#008ffe',
            fontSize: '40px',
            fontFamily: 'UTM-APTIMABOLD',
            marginTop: '-20px'
          }}>
            {LANGUAGE === 'VI'
              ?
              'ĐĂNG KÝ'
              :
              'REGISTER'
            }
          </p>

          <h3
            style={{ color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}
          >
            1. Name <span className='text-danger'>*</span>
          </h3>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3 className='d-none' style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            {LANGUAGE === 'VI'
              ?
              '2. Năm sinh'
              :
              '2. Year of Birth'
            }
          </h3>
          <input
            className='d-none'
            value={birthYear}
            onChange={(e) => setBirthYear(e.target.value)}
            type="number"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3 className='d-none' style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            {LANGUAGE === 'VI'
              ?
              '3. Giới tính'
              :
              '3. Gender'
            }
          </h3>
          <select
            className='d-none'
            onChange={(e) => setGender(e.target.value)}
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '10px'
            }}
          >
            <option key='male' value='male' style={{ fontFamily: 'UTM-APTIMA' }}>
              {LANGUAGE === 'VI'
                ?
                'Nam'
                :
                'Male'
              }
            </option>
            <option key='female' value='female' style={{ fontFamily: 'UTM-APTIMA' }}>
              {LANGUAGE === 'VI'
                ?
                'Nữ'
                :
                'Female'
              }
            </option>
          </select>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            2. Phone number <span className='text-danger'>*</span>
          </h3>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />
          <p className='d-none' style={{fontSize: '18px', color: '#3d99ff', fontFamily: 'UTM-APTIMA', textAlign: 'justify' }}>* Nếu xảy ra trường hợp một gia đình chỉ có 1 SĐT nhưng lại có nhiều thành viên tham gia phân tích, bạn có thể thêm vào phía sau 1 hoặc 2 số nữa để tránh trùng lặp</p>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            3. Referrer phone number
          </h3>
          <input
            value={referPhone}
            onChange={(e) => setReferPhone(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <p
            style={{
              color: '#0dc735',
              fontSize: '24px',
              textAlign: 'justify',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '20px'
            }}
          >
            How happy do you consider yourself?
          </p>

          <div>
            <div
              onClick={() => {setQuestionTwo("TWO_A")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_A" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_A" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 1: Rất hạnh phúc và luôn cảm thấy biết ơn'
                :
                'Level 1: Happiness and deep gratitude'
              }
            </div>


            <div
              onClick={() => {setQuestionTwo("TWO_B")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_B" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_B" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 2: Cảm thấy hài lòng và thoải mái'
                :
                'Level 2: Satisfied and comfortable'
              }
            </div>

            <div
              onClick={() => {setQuestionTwo("TWO_C")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_C" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_C" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 3: Cảm thấy bình thường và ổn định'
                :
                'Level 3: Normal and stable'
              }
            </div>

            <div
              onClick={() => {setQuestionTwo("TWO_D")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_D" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_D" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 4: Khó hài lòng và thường lo lắng'
                :
                'Level 4: Difficult to please and often anxious'
              }
            </div>

            <div
              onClick={() => {setQuestionTwo("TWO_E")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_E" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_E" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 5: Cảm thấy bất hạnh và thất vọng'
                :
                'Level 5: Feeling unhappy and disappointed'
              }
            </div>
          </div>

          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {errorMsg && <h3
              style={{
                color: 'red'
              }}
            >{errorMsg}
            </h3>}

          </div>

          <button
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: '#0dc735',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              if (!name) {
                setErrorMsg('Please input your name')
                return false
              }
              if (!birthYear) {
                setErrorMsg('Please input your birth year')
                return false
              }
              if (!phone) {
                setErrorMsg('Please input your phone number')
                return false
              }
              if (!questionTwo) {
                setErrorMsg('Please pick how happy do you consider yourself')
                return false
              }
              confirmRegister()
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'ĐĂNG KÝ'
                :
                'REGISTER'
              }
            </span>
          </button>


        </div>


      </div>

    </div>
  )
}

export {StepOne}
