/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";

const Package = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(false);
  const [packageProduct, setPackageProduct] = useState('');

  const userInfo = useSelector(state => state.userInfo)
  const isParentHelp = userInfo.is_parent_help === 1
  const LANGUAGE = userInfo.language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >


        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >

          <p
            style={{
              color: '#2abf00',
              fontSize: '32px',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'CÁC GÓI'
              :
              'PACKAGE'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-20px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'SẢN PHẨM'
              :
              'PRODUCT'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-25px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'TIẾP THEO'
              :
              'NEXT'
            }
          </p>

          <div
            onClick={() => {setPackageProduct("PACKAGE_ONE")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: packageProduct === "PACKAGE_ONE" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: packageProduct === "PACKAGE_ONE" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Gói 1: Báo cáo phân tích tính cách bằng camera trí tuệ nhân tạo: 100.000đ/ lần.'
              :
              'Package 1: Career orientation experience and career thinking course (taken after completing Package 1): 100,000 VND/time.'
            }
          </div>

          <div
            onClick={() => {setPackageProduct("PACKAGE_TWO")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: packageProduct === "PACKAGE_TWO" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: packageProduct === "PACKAGE_TWO" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Gói 2: Báo cáo định hướng nghề nghiệp và khóa học tư duy hướng nghiệp: 200.000/ lần'
              :
              'Package 2: Career orientation report and career thinking course: 200,000/time'
            }
          </div>

          <div
            onClick={() => {setPackageProduct("PACKAGE_THREE")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: packageProduct === "PACKAGE_THREE" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: packageProduct === "PACKAGE_THREE" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Gói 3: Bản báo cáo đầy đủ 12 thông tin chi tiết về tính cách, bản đồ giao tiếp trong gia đình và các khóa học online tặng kèm: 1.600.000đ/ bản.'
              :
              'Package 3: Full report with 12 detailed personality information, family communication map and free online courses: 1,600,000 VND/copy.'
            }
          </div>

          <div
            style={{
              height: '60px',
            }}
          >
          </div>

          <button
            disabled={ packageProduct ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: packageProduct ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              if (packageProduct === 'PACKAGE_ONE') {
                history.push('/donate')
              } else if (packageProduct === 'PACKAGE_TWO') {
                history.push('/donate2')
              } else if (packageProduct === 'PACKAGE_THREE') {
                history.push('/donate3')
              }
            }}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMA'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'XÁC NHẬN'
                :
                'CONFIRM'
              }
            </span>
          </button>

        </div>


      </div>

    </div>
  )
}

export {Package}
