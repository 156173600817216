import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const StepZero1 = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [language, setLanguage] = useState('male');
  const [isChild, setIsChild] = useState(0);
  const [provinceId, setProvinceId] = useState(1);
  const [districtId, setDistrictId] = useState(1);
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [currentDistricts, setCurrentDistricts] = useState([])
  const [isCorrect, setIsCorrect] = useState(true);

  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >

          <h1 style={{
            color: '#6858ab',
            fontSize: '30px',
            textAlign: 'center',
            fontFamily: 'UTM-APTIMABOLD'
          }}>
            {LANGUAGE === 'VI'
              ?
              'ĐĂNG KÝ ANBI'
              :
              'REGISTER ANBI'
            }
          </h1>

          <h2 style={{
            color: '#ff6c00',
            fontSize: '22px',
            textAlign: 'justify',
            fontFamily: 'UTM-APTIMABOLD'
          }}>
            {LANGUAGE === 'VI'
              ?
              'CÔNG NGHỆ NHẬN DIỆN TÍNH CÁCH QUA VIỆC PHÂN TÍCH KHUÔN MẶT BẰNG CAMERA TRÍ TUỆ NHÂN TẠO'
              :
              'PERSONALITY RECOGNITION TECHNOLOGY THROUGH FACIAL ANALYSIS USING ARTIFICIAL INTELLIGENCE CAMERA'
            }
          </h2>

          <h1 style={{
            color: '#6858ab',
            fontSize: '22px',
            textAlign: 'center',
            fontFamily: 'UTM-APTIMABOLD',
            marginTop: '25px'
          }}>
            {LANGUAGE === 'VI'
              ?
              '⚠ LƯU Ý QUAN TRỌNG ⚠'
              :
              '⚠ IMPORTANT NOTE ⚠'
            }
          </h1>
          <h3
            style={{
              color: 'rgb(120, 120, 120)',
              textAlign: 'justify',
              lineHeight: 1.7,
              fontSize: '26px',
              whiteSpace: 'pre-line',
              fontWeight: 'normal',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            <span
              style={{
                color: 'rgb(120, 120, 120)',
                textAlign: 'justify',
                lineHeight: 1.7,
                fontSize: '26px',
                whiteSpace: 'pre-line',
                fontWeight: 'normal',
                fontFamily: 'UTM-APTIMA'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                '1. Bạn vui lòng đọc thật kỹ và hoàn thành nghiêm túc phần thông tin đăng ký, vì mỗi khuôn mặt tham gia phân tích sẽ'
                :
                '1. Please read carefully and seriously complete the registration information section, because '
              }
            </span>
            <span
              style={{
                color: '#ea2424',
                textAlign: 'justify',
                lineHeight: 1.7,
                fontSize: '26px',
                whiteSpace: 'pre-line',
                fontWeight: 'normal',
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                ' chỉ được trợ giá một lần duy nhất'
                :
                ' each face participating in the analysis will only be subsidized once'
              }
            </span>
            <span
              style={{
                color: 'rgb(120, 120, 120)',
                textAlign: 'justify',
                lineHeight: 1.7,
                fontSize: '26px',
                whiteSpace: 'pre-line',
                fontWeight: 'normal',
                fontFamily: 'UTM-APTIMA'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                '. (Phí phân tích lại: 100 USD/lần) \n2. Học sinh từ 4 đến 9 tuổi cần được phụ huynh hoặc giáo viên giúp hoàn thành phần đăng ký này. \n3. Cố gắng giữ bí mật bản báo cáo vì tính cách cũng là thông tin quan trọng cần được bảo vệ, tránh để kẻ xấu lợi dụng. \n4. Cơ chế hoạt động của Anbi: Đội ngũ nghiên cứu của chúng tôi đã chỉ ra rằng mỗi khi con người xuất hiện cảm xúc, các phản ứng trong não bộ sẽ để lại những điểm sắc tố đặc trưng dưới da của chúng ta. Việc Anbi dùng camera trí tuệ nhân tạo để phân tích loại màu, vị trí và mật độ phân bố sẽ giúp chúng tôi nhận ra bạn thường sử dụng loại cảm xúc nào, từ đó phán đoán cách bạn phản ứng, cũng chính là tính cách của bạn.'
                :
                '. (Reanalysis fee: 100 USD/time) \n2. Students from 4 to 9 years old need help from their parents or teachers to complete this registration. \n3. Try to keep the report confidential because personality is also important information that needs to be protected to avoid being taken advantage of by bad people. \n4. Anbi\'s mechanism of action: Our research team has shown that every time people have emotions, reactions in the brain will leave characteristic pigment spots under our skin. Anbi\'s use of artificial intelligence cameras to analyze color type, location and distribution density will help us recognize what emotions you often use, thereby judging how you react, which is also your personality.'
              }
            </span>
          </h3>


          <div
            style={{
              height: '50px',
            }}
          >
          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#00a600' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              history.push('/step-one')
            }}
          >
            <span
              style={{
                fontSize: '18px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'BẮT ĐẦU ĐĂNG KÝ'
                :
                'START REGISTRATION'
              }

            </span>
          </button>
        </div>


      </div>

    </div>
  )
}

export {StepZero1}
