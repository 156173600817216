/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";

const StepTwo3 = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(false);
  const [questionSix, setQuestionSix] = useState('');

  const userInfo = useSelector(state => state.userInfo)
  const isParentHelp = userInfo.is_parent_help === 1
  const LANGUAGE = userInfo.language

  useEffect(() => {
    if (questionSix) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [questionSix])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >




        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <h1
            style={{
              color: '#003345',
              fontSize: '24px',
              textAlign: 'center',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Bạn muốn học bằng phương pháp nào nhất ?'
                :
                'Which method do you want to learn the most?'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Quý vị muốn bạn nhỏ tham gia học bằng phương pháp nào nhất ?'
                :
                'Which method do you most want your child to participate in learning?'
              }
            </>}

          </h1>
          <div
            style={{
              height: '40px',
            }}
          >
          </div>


          <div
            onClick={() => {setQuestionSix("SIX_A")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionSix === "SIX_A" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              backgroundColor: questionSix === "SIX_A" ? '#00a600' : 'white',
              marginTop: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Học trực tiếp với giảng viên'
                :
                'Learn directly with instructors'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Học trực tiếp với giảng viên'
                :
                'Learn directly with instructors'
              }
            </>}

          </div>

          <div
            onClick={() => {setQuestionSix("SIX_B")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionSix === "SIX_B" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              backgroundColor: questionSix === "SIX_B" ? '#00a600' : 'white',
              marginTop: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Học qua video và tài liệu chuẩn bị sẵn'
                :
                'Learn through videos and prepared materials'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Học qua video và tài liệu chuẩn bị sẵn'
                :
                'Learn through videos and prepared materials'
              }
            </>}

          </div>

          <div
            onClick={() => {setQuestionSix("SIX_C")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionSix === "SIX_C" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              backgroundColor: questionSix === "SIX_C" ? '#00a600' : 'white',
              marginTop: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Học trong môi trường thực tế ảo'
                :
                'Learn in a virtual reality environment'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Học trong môi trường thực tế ảo'
                :
                'Learn in a virtual reality environment'
              }
            </>}

          </div>




          <div
            style={{
              height: '60px',
            }}
          >
          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#00a600' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)
              history.push('/step-three')
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMA'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC'
                :
                ' CONTINUE'
              }
            </span>
          </button>

        </div>


      </div>

    </div>
  )
}

export {StepTwo3}
